import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { lpForm } from 'lp-form'
import {
  Field,
  propTypes as formPropTypes,
  formValueSelector,
} from 'redux-form'
import {
  persistSubmitSucceeded,
  formatObjectsToIds,
  parseIdsToObjects,
  replaceResources,
  serializeOptions,
} from 'utils'

import { CheckboxGroup, CheckboxFieldset } from 'components'
import { InputError, SubmitButton } from 'lp-components'
import { modifyProps } from 'lp-hoc'

const propTypes = {
  ...formPropTypes,
  standardTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  arialabelledby: PropTypes.string.isRequired,
}

const defaultProps = {}

function MultipleStandardsForm({
  handleSubmit,
  isSaving,
  standardTypes,
  saved,
  submitting,
  error,
  ariaLabelledby,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <CheckboxFieldset invalid={!!error} disabled={isSaving}>
        <Field
          name="standards"
          aria-labelledby={ariaLabelledby}
          label={false}
          component={CheckboxGroup}
          options={serializeOptions(standardTypes)}
          format={formatObjectsToIds('standardId')}
          parse={parseIdsToObjects('standardId')}
        />
      </CheckboxFieldset>
      {error && <InputError error={error} touched invalid />}
      <div className="button-wrapper">
        <SubmitButton {...{ pristine: saved, submitting }}>
          Save Response
        </SubmitButton>
      </div>
    </form>
  )
}

MultipleStandardsForm.propTypes = propTypes
MultipleStandardsForm.defaultProps = defaultProps

const select = formValueSelector('school-multiple-standards')

function mapStateToProps(state) {
  return {
    standards: select(state, 'standards'),
  }
}

function modifyBeforeSubmit({ initialValues }) {
  return {
    beforeSubmit: ({ standards }) => {
      const resources = replaceResources({
        old: initialValues.standards,
        new: standards,
      })
      return { standards: resources }
    },
  }
}

export default compose(
  connect(mapStateToProps),
  modifyProps(modifyBeforeSubmit),
  lpForm({
    name: 'school-multiple-standards',
    enableReinitialize: true,
  }),
  persistSubmitSucceeded()
)(MultipleStandardsForm)
