import React from 'react'
import { pure } from 'recompose'
import PropTypes from 'prop-types'
import * as Types from 'types'
import CommunityImage from './community-image'

const propTypes = {
  logos: PropTypes.arrayOf(Types.image).isRequired,
}

const defaultProps = {}

function MutlipleLogoBlock({ logos }) {
  return (
    <div className="logo-block mutliple-logo-block">
      {logos.map((logo) => {
        return (
          <div key={logo.src} className="logo-block-item">
            <CommunityImage image={logo} />
          </div>
        )
      })}
    </div>
  )
}

MutlipleLogoBlock.propTypes = propTypes
MutlipleLogoBlock.defaultProps = defaultProps

export default pure(MutlipleLogoBlock)
