import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  SelectAllCheckboxGroup,
  FilterMenuItem,
  SearchableCheckboxGroups,
} from 'components'
import { CheckboxGroup, Select } from 'lp-components'
import { get, map, mapValues, size, startCase } from 'lodash'
import { change, Field, FormSection, formValueSelector } from 'redux-form'
import { formatOptions, sortedAndFormattedOptions, useCommunity } from 'utils'
import classnames from 'classnames'

const propTypes = {
  changeField: PropTypes.func.isRequired,
  ethnicityType: PropTypes.string,
  filterOptions: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
}

const defaultProps = {
  ethnicityType: '',
}

function SchoolSearchFilterFields({
  changeField,
  ethnicityType,
  filterOptions,
  formName,
  ...filterMenuProps
}) {
  const community = useCommunity()
  const schoolSearchFilters = useMemo(
    () => get(community, 'search.schoolSearchFilters', []),
    [community]
  )
  const subDisciplineOptionGroups = useMemo(() => {
    return mapValues(filterOptions.subDisciplines, sortedAndFormattedOptions)
  }, [filterOptions.subDisciplines])

  const resourceProgramInterestsOptionGroups = useMemo(() => {
    return mapValues(
      filterOptions.resourceProgramInterests,
      sortedAndFormattedOptions
    )
  }, [filterOptions.resourceProgramInterests])

  const ethnicityOptions = useMemo(() => {
    return map(
      get(filterOptions, 'studentDemographics.ethnicity'),
      (ethnicity, name) => {
        return {
          key: ethnicity.displayName,
          value: name,
        }
      }
    )
  }, [filterOptions.studentDemographics])

  // Pre-select the first available option (based on selected ethnicity)
  const initializeEthnicityOption = useCallback(
    (e) => {
      const selectedEthnicityType = e.target.value
      const newValue = get(
        filterOptions,
        `studentDemographics.ethnicity.${selectedEthnicityType}.options[0].value`,
        ''
      )

      return changeField(
        formName,
        'filters.studentDemographics.ethnicity',
        newValue
      )
    },
    [formName, filterOptions.studentDemographics]
  )

  function showFilter(menuItemName) {
    if (menuItemName === 'network' && size(filterOptions.networks) > 1) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="network"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <Field
            name="network"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.networks)}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'creativeSchoolsCategory' &&
      size(filterOptions.creativeSchoolsCategory) > 1
    ) {
      return (
        <FilterMenuItem
          name="creativeSchoolsCategory"
          key={menuItemName}
          {...filterMenuProps}
        >
          <Field
            name="creativeSchoolsCategory"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.creativeSchoolsCategory)}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'instructorsTeaching' &&
      size(filterOptions.instructorsTeaching) > 1
    ) {
      return (
        <FilterMenuItem
          name="instructorsTeaching"
          key={menuItemName}
          {...filterMenuProps}
        >
          <Field
            name="instructorsTeaching"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.instructorsTeaching)}
          />
        </FilterMenuItem>
      )
    }
    if (menuItemName === 'schoolType' && size(filterOptions.schoolTypes) > 1) {
      return (
        <FilterMenuItem
          name="schoolType"
          key={menuItemName}
          {...filterMenuProps}
        >
          <Field
            name="schoolType"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.schoolTypes)}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'organizationPartnersCount' &&
      size(filterOptions.partnersCount) > 1
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="organizationPartnersCount"
          displayName="Number of Organization Partners"
          {...filterMenuProps}
        >
          <Field
            name="organizationPartnersCount"
            options={formatOptions(filterOptions.partnersCount)}
            component={CheckboxGroup}
            label="Number of Organization Partners"
            labelComponent={CheckboxGroupLegend}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'programsOffered' &&
      size(filterOptions.subDisciplines) > 1
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="programsOffered"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <SearchableCheckboxGroups
            formName={formName}
            formPath="filters.programsOffered"
            groupLabel="Programs Offered"
            optionGroups={subDisciplineOptionGroups}
            headingLevel="3"
            component={SelectAllCheckboxGroup}
            className="filter-search"
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'disciplineInterests' &&
      size(filterOptions.subDisciplines) > 1
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="disciplineInterests"
          {...filterMenuProps}
          className="large-width full-height"
        >
          <SearchableCheckboxGroups
            formName={formName}
            formPath="filters.disciplineInterests"
            groupLabel="Discipline Interests"
            optionGroups={subDisciplineOptionGroups}
            headingLevel="3"
            component={SelectAllCheckboxGroup}
            className="filter-search"
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'resourceProgramInterests' &&
      size(filterOptions.resourceProgramInterests) > 1
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="resourceProgramInterests"
          displayName="Resource/Program Interests"
          {...filterMenuProps}
          className="large-width"
        >
          <SearchableCheckboxGroups
            formName={formName}
            formPath="filters.resourceProgramInterests"
            groupLabel="Resource / Program Interests"
            optionGroups={resourceProgramInterestsOptionGroups}
            headingLevel="3"
            component={SelectAllCheckboxGroup}
            className="filter-search"
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'coursesOffered' &&
      size(filterOptions.coursesOffered) > 1
    ) {
      return (
        <FilterMenuItem
          name="coursesOffered"
          key={menuItemName}
          {...filterMenuProps}
        >
          <Field
            name="coursesOffered"
            component={CheckboxGroup}
            label="Courses Offered"
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.coursesOffered)}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'studentDemographics' &&
      size(filterOptions.studentDemographics) > 1
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="studentDemographics"
          {...filterMenuProps}
          getAppliedFilterCount={(name) =>
            filterMenuProps.getAppliedFilterCount(name, {
              ignorePaths: ['ethnicityType'],
            })
          }
          className="large-width full-height"
        >
          <FormSection name="studentDemographics">
            <div className="row">
              <Field
                name="ethnicityType"
                label="Ethnicity"
                component={Select}
                options={ethnicityOptions}
                placeholder="Select"
                onChange={initializeEthnicityOption}
                className={classnames({ 'col-6': ethnicityType })}
                enablePlaceholderOption
              />
              {ethnicityType && (
                <Field
                  name="ethnicity"
                  component={Select}
                  label="Percent"
                  aria-label="Ethnicity Percentage"
                  options={formatOptions(
                    get(
                      filterOptions,
                      `studentDemographics.ethnicity.${ethnicityType}.options`
                    )
                  )}
                  className="col-6"
                />
              )}
            </div>
            <Field
              name="limitedEnglish"
              label={get(
                filterOptions,
                'studentDemographics.limitedEnglish.displayName'
              )}
              component={Select}
              options={formatOptions(
                get(filterOptions, 'studentDemographics.limitedEnglish.options')
              )}
              placeholder="Select"
              enablePlaceholderOption
            />
            <Field
              name="reducedLunch"
              label={get(
                filterOptions,
                'studentDemographics.reducedLunch.displayName'
              )}
              component={Select}
              options={formatOptions(
                get(filterOptions, 'studentDemographics.reducedLunch.options')
              )}
              placeholder="Select"
              enablePlaceholderOption
            />
            <Field
              name="specialEducation"
              label={get(
                filterOptions,
                'studentDemographics.specialEducation.displayName'
              )}
              component={Select}
              options={formatOptions(
                get(
                  filterOptions,
                  'studentDemographics.specialEducation.options'
                )
              )}
              placeholder="Select"
              enablePlaceholderOption
            />
          </FormSection>
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'schoolDistrict' &&
      size(filterOptions.schoolDistrict) > 1
    ) {
      return (
        <FilterMenuItem
          name="schoolDistrict"
          key={menuItemName}
          {...filterMenuProps}
        >
          <Field
            name="schoolDistrict"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.schoolDistrict)}
          />
        </FilterMenuItem>
      )
    }
    if (
      menuItemName === 'openToPartnerships' &&
      filterOptions.openToPartnerships
    ) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="openToPartnerships"
          displayName="Looking for Partnerships?"
          isBooleanFilter
          {...filterMenuProps}
        >
          <Field
            name="openToPartnerships"
            component={CheckboxGroup}
            label="Looking for Partnerships?"
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.openToPartnerships)}
          />
        </FilterMenuItem>
      )
    }
    if (menuItemName === 'region' && size(filterOptions.region) > 1) {
      return (
        <FilterMenuItem
          key={menuItemName}
          name="region"
          displayName="Region"
          {...filterMenuProps}
        >
          <Field
            name="region"
            component={CheckboxGroup}
            labelComponent={CheckboxGroupLegend}
            options={formatOptions(filterOptions.region)}
          />
        </FilterMenuItem>
      )
    }
  }

  return (
    <React.Fragment>
      {schoolSearchFilters.map((filterName) => {
        return showFilter(filterName)
      })}
    </React.Fragment>
  )
}

function CheckboxGroupLegend({ label, name }) {
  return <legend className="visually-hidden">{label || startCase(name)}</legend>
}

function mapStateToProps(state, { formName }) {
  const formSelector = formValueSelector(formName)
  return {
    ethnicityType: formSelector(
      state,
      'filters.studentDemographics.ethnicityType'
    ),
  }
}

const mapDispatchToProps = {
  changeField: change,
}

SchoolSearchFilterFields.propTypes = propTypes
SchoolSearchFilterFields.defaultProps = defaultProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchoolSearchFilterFields)
