import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  SectionHeader,
  StatsList,
  StatsListMetricItem,
  MapDisplay,
  LoadingContainer,
  MapPartnerTable,
  SegmentedControl,
  CertificationCategory,
} from 'components'
import {
  ArtsDisciplinesTabContent,
  CertificationBadge,
  InstructorList,
  CoursesExpandableDetails,
  ProgramsTabContent,
  ResourcesTabContent,
  SchoolDisciplineTypeTabContent,
  IncompleteSurveyNote,
  SpaceTypesContent,
} from '../components'
import * as Types from 'types'
import SchoolTabs from './school-tabs'
import { Spinner, TabBar } from 'lp-components'
import {
  filter,
  uniqBy,
  first,
  get,
  isEmpty,
  reduce,
  find,
  isNil,
  size,
  mapValues,
  flatMap,
  compact,
  map,
  sortBy,
} from 'lodash'
import { isIgnoredType, serializeOptions, useCommunity } from 'utils'
import {
  DataIcon,
  ProgramsIcon,
  InstructorsIcon,
  SchoolIcon,
  PartnershipsIcon,
  SpaceTypeAllocationsIcon,
} from 'components/icons'
import { SURVEY_ATTEMPT_STATES, INCOMPLETE_SURVEY_SCORE } from 'config'

const propTypes = {
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  programTypes: PropTypes.arrayOf(Types.programType).isRequired,
  resourceTypes: PropTypes.arrayOf(Types.resourceType).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSchoolYearDisplay: PropTypes.func.isRequired,
  getSchoolYear: PropTypes.func.isRequired,
  school: Types.school,
  schoolLocation: Types.location,
  partnerLocations: PropTypes.arrayOf(Types.location),
  selectedSchoolYearId: PropTypes.number,
  currentSchoolYear: Types.schoolYear.isRequired,
}

const defaultProps = {
  school: null,
}

const DataNote = () => (
  <p>
    *This data is taken from the previous school year, as this year's data is
    not yet available.
  </p>
)

const getSubdisciplineOptions = (disciplines, disciplineName) => {
  const discipline = disciplines.find(({ name }) => name === disciplineName)
  if (!discipline) return []

  return discipline.subDisciplines
}

const countSubDisciplines = (subdisciplines) => {
  return uniqBy(
    subdisciplines.filter((discipline) => !isIgnoredType(discipline)),
    'subDisciplineId'
  ).length
}

const formatEnrollmentRatio = (value) => {
  const formattedValue = Math.floor(value)
  if (formattedValue === 0) return formattedValue

  return `1/${formattedValue}`
}

const roundMetric = Math.round

const countCourses = (courses) => {
  return reduce(
    courses,
    (sum, currentDiscipline) => {
      return sum + currentDiscipline.coursesData.length
    },
    0
  )
}

const courseYear = (courses) => {
  const discipline = find(courses)
  const disciplineCourses = discipline.coursesData
  const course = first(disciplineCourses)
  return course.schoolYearId
}

const InterestTabs = {
  ARTS_DISCIPLINES: 'disciplines',
  RESOURCES: 'resources',
  PROGRAMS: 'programs',
}

function SchoolContent({
  disciplines,
  programTypes,
  resourceTypes,
  isLoading,
  getSchoolYearDisplay,
  getSchoolYear,
  partnerLocations,
  school,
  schoolLocation,
  selectedSchoolYearId,
  currentSchoolYear,
}) {
  const { dataDashboardEnabled } = useCommunity()

  if (!school) return <Spinner />

  const [mapShown, setMapShown] = useState(false)
  const [interestsTab, setInterestsTab] = useState(
    InterestTabs.ARTS_DISCIPLINES
  )
  const [schoolDisciplineTab, setSchoolDisciplineTab] = useState(
    Types.DISCIPLINE_TYPES.DANCE
  )

  const schoolDisciplineTabOptions = useMemo(
    () => serializeOptions(disciplines, { value: 'name' }),
    [disciplines]
  )

  const {
    category,
    detail,
    subDisciplines,
    instructors,
    partnerships,
    interests,
    report,
    courses,
    averageMinutesPerWeek,
    enrollmentRatio,
    participation,
    surveyState,
    specificInterest,
    metricScore,
    spaceTypeAllocations,
  } = school

  const filteredInterests = useMemo(() => {
    return mapValues(interests, (values) => {
      return filter(values, (interest) => !isIgnoredType(interest))
    })
  }, [interests])

  const combinedInterests = useMemo(() => {
    return flatMap(filteredInterests, (values) => values)
  }, [filteredInterests])

  // Derive from Course data (_not_ Programs Offered)
  const disciplinesOffered = useMemo(() => {
    const courseDisciplines = map(courses, ({ coursesData }) => {
      const discipline = disciplines.find(
        (discipline) =>
          discipline.id === get(first(coursesData), 'disciplineId')
      )

      if (!discipline) return null
      return discipline
    })
    return compact(courseDisciplines)
  }, [disciplines, courses])

  const isSurveyAttemptComplete = surveyState === SURVEY_ATTEMPT_STATES.COMPLETE
  const dashboardData = {
    averageMinutesPerWeek,
    enrollmentRatio,
    courseParticipation: get(participation, 'enrollmentRatio'),
    numberOfDisciplines: size(disciplinesOffered),
    numberOfPartnerships: size(partnerships),
  }

  const hasPartners = !isEmpty(partnerships)
  const hasIntructors = !isEmpty(instructors)
  const hasResources = !isEmpty(filteredInterests.requestedResourceTypes)
  const hasCourses = !isEmpty(courses)
  const hasInterests = !isEmpty(combinedInterests)
  const hasSubDisciplines = !isEmpty(subDisciplines)
  const hasSpaceTypeAllocations = !isEmpty(spaceTypeAllocations)
  const hasCertification = !!report

  // only display dashboard data if survey has been completed and it is enabled in the community config
  const displayDashboardData =
    isSurveyAttemptComplete && !isEmpty(dashboardData) && dataDashboardEnabled
  const schoolYearIdForSubDisciplines = get(
    first(subDisciplines),
    'schoolYearId'
  )
  const schoolYearIdForInterests = get(first(combinedInterests), 'schoolYearId')
  const schoolYearId = get(detail, 'schoolYearId') || 0 // schools may not have information captured for every school year (enhancement pending)
  const programNames = programTypes.map(
    (programType) => programType.displayName
  )

  const selectedSchoolYearIsCurrent =
    selectedSchoolYearId === currentSchoolYear.id

  const whatsHappeningNowText = get(specificInterest, 'otherText')

  const row1 = []
  const row2 = []
  const row3 = []

  metricScore?.categories?.forEach((category) => {
    if (category.row === 1) row1.push(category)
    if (category.row === 2) row2.push(category)
    if (category.row === 3) row3.push(category)
  })

  const hasContent =
    hasPartners ||
    hasIntructors ||
    hasResources ||
    hasCertification ||
    hasSubDisciplines ||
    hasCourses
  hasSpaceTypeAllocations || displayDashboardData || metricScore

  if (!hasContent) return null

  const schoolYear = getSchoolYear(metricScore?.schoolYearId)
  const showSchoolYear = !isEmpty(metricScore?.categories)

  return (
    <LoadingContainer isLoading={isLoading}>
      {/* Side Nav */}
      <SchoolTabs
        hasPartners={hasPartners}
        hasTeachers={hasIntructors}
        hasResources={hasResources}
        hasCertification={hasCertification}
        hasSubDisciplines={hasSubDisciplines}
        hasCourses={hasCourses}
        hasDashboard={displayDashboardData}
      />

      {/* Note about Incomplete Data for Non-Chicago Schools */}
      {!report && !isSurveyAttemptComplete && !selectedSchoolYearIsCurrent && (
        <IncompleteSurveyNote />
      )}

      {/*Certification Score V2*/}
      {metricScore && (
        <section name="csc">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <DataIcon />
              </div>
              <h2>Creative Schools Certification</h2>
              {schoolYear && (
                <div className="text-block">
                  <p>
                    The Creative Schools Certification is at the core of the CPS
                    Arts Education Plan. It is a summary measure of the quality
                    of arts education available in each school, and therefore
                    functions as a decision-making roadmap for stakeholders
                    working to expand the arts in schools.
                    {showSchoolYear &&
                      ` The Certification information here is based on the ${schoolYear?.number} school year.`}
                  </p>
                </div>
              )}
            </SectionHeader>
            {metricScore.showFinalScore && (
              <CertificationBadge
                level={metricScore.level}
                description={metricScore.description}
              />
            )}
            {/*do not show categories if data is incomplete*/}
            {metricScore.finalScore !== INCOMPLETE_SURVEY_SCORE && (
              <StatsList>
                <div className="phase-1-stats">
                  {sortBy(row1, 'column').map((category) => {
                    return (
                      <CertificationCategory
                        key={category.id}
                        category={category}
                      />
                    )
                  })}
                </div>
                <div className="phase-2-stats">
                  {sortBy(row2, 'column').map((category) => {
                    return (
                      <CertificationCategory
                        key={category.id}
                        category={category}
                      />
                    )
                  })}
                </div>
                <div className="phase-2-stats">
                  {sortBy(row3, 'column').map((category) => {
                    return (
                      <CertificationCategory
                        key={category.id}
                        category={category}
                      />
                    )
                  })}
                </div>
              </StatsList>
            )}
          </div>
        </section>
      )}

      {/* Data Dashboard */}
      {!report && displayDashboardData && (
        <section name="dashboard">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <DataIcon />
              </div>
              <h2>Data Dashboard</h2>
            </SectionHeader>
            <StatsList>
              <div className="phase-1-stats">
                {!isNil(dashboardData.enrollmentRatio) && (
                  <StatsListMetricItem
                    stat={formatEnrollmentRatio(dashboardData.enrollmentRatio)}
                    label="Ratio of Arts Instructors to Students"
                    hideExpand
                  />
                )}
                {category !== Types.HIGH_SCHOOL_TYPE &&
                  !isNil(dashboardData.averageMinutesPerWeek) && (
                    <StatsListMetricItem
                      stat={roundMetric(dashboardData.averageMinutesPerWeek)}
                      label="Average Number of Arts Minutes Per Week"
                      hideExpand
                    ></StatsListMetricItem>
                  )}
                {category !== Types.HIGH_SCHOOL_TYPE &&
                  category !== Types.COMBO_SCHOOL_TYPE &&
                  !isNil(dashboardData.courseParticipation) && (
                    <StatsListMetricItem
                      stat={`${roundMetric(
                        dashboardData.courseParticipation
                      )}%`}
                      label="of Students Took At Least One Arts Course"
                      hideExpand
                    ></StatsListMetricItem>
                  )}
                {(category === Types.HIGH_SCHOOL_TYPE ||
                  category === Types.COMBO_SCHOOL_TYPE) && (
                  <StatsListMetricItem
                    stat={dashboardData.numberOfDisciplines}
                    label="Arts Disciplines in which Courses are Offered"
                    hideExpand
                  />
                )}
                {dashboardData.numberOfPartnerships > 0 && (
                  <StatsListMetricItem
                    stat={dashboardData.numberOfPartnerships}
                    label="Arts Partnerships"
                    hideExpand
                  />
                )}
              </div>
            </StatsList>
          </div>
        </section>
      )}

      {/* Interests */}
      {hasInterests && (
        <section name="interests" className="interests-wrapper">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <ProgramsIcon />
                <span>{size(combinedInterests)}</span>
              </div>
              <h2>
                Interests {getSchoolYearDisplay(schoolYearIdForInterests)}
              </h2>
              <div className="text-block">
                <p>
                  Schools are able to identify their interests in additional
                  arts programming and resources via the Creative Schools
                  Survey. The following items with check marks provide a sense
                  of the arts partnerships and resources this school needs, as
                  provided on the most recent survey.
                </p>
                {schoolYearIdForInterests !== schoolYearId && <DataNote />}
              </div>
            </SectionHeader>

            <TabBar
              options={[
                {
                  key: 'Arts Disciplines',
                  value: InterestTabs.ARTS_DISCIPLINES,
                },
                { key: 'Types of Programs', value: InterestTabs.PROGRAMS },
                { key: 'Resources', value: InterestTabs.RESOURCES },
              ]}
              value={interestsTab}
              onChange={setInterestsTab}
              className="accordion-tabs-minimal"
              activeClassName="is-active"
            />
            {interestsTab === InterestTabs.ARTS_DISCIPLINES && (
              <div className="tab-content tab-content-1">
                <ArtsDisciplinesTabContent
                  artsDisciplines={interests.requestedSubDisciplines}
                  disciplines={disciplines}
                />
              </div>
            )}
            {interestsTab === InterestTabs.PROGRAMS && (
              <div className="tab-content tab-content-2">
                <ProgramsTabContent
                  allProgramTypes={programTypes}
                  artsProgrammingResources={interests.requestedProgramTypes}
                  isActive={interestsTab === 'programs'}
                  setActive={() => setInterestsTab('programs')}
                />
              </div>
            )}
            {interestsTab === InterestTabs.RESOURCES && (
              <div className="tab-content tab-content-3">
                <ResourcesTabContent
                  artsProgrammingResources={interests.requestedResourceTypes}
                  allResourceTypes={resourceTypes}
                  isActive={interestsTab === 'resources'}
                  setActive={() => setInterestsTab('resources')}
                />
              </div>
            )}
            {whatsHappeningNowText && (
              <div className="card">
                <p>{whatsHappeningNowText}</p>
              </div>
            )}
          </div>
        </section>
      )}

      {/* Arts Instructors */}
      {hasIntructors && (
        <section name="instructors" className="instructors-block">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <InstructorsIcon />
                <span>{instructors.length}</span>
              </div>
              <h2>Arts Instructors</h2>
            </SectionHeader>
            <InstructorList instructors={instructors} />
          </div>
        </section>
      )}

      {/* Program Offerings */}
      {hasSubDisciplines && (
        <section name="subDisciplines" className="program-offerings-wrapper">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <ProgramsIcon />
                <span>{countSubDisciplines(subDisciplines)}</span>
              </div>
              <h2>
                Program Offerings{' '}
                {getSchoolYearDisplay(schoolYearIdForSubDisciplines)}
              </h2>
              {schoolYearIdForSubDisciplines !== schoolYearId && <DataNote />}
            </SectionHeader>
            <TabBar
              options={schoolDisciplineTabOptions}
              value={schoolDisciplineTab}
              onChange={setSchoolDisciplineTab}
              className="accordion-tabs-minimal"
              activeClassName="is-active"
            />
            {schoolDisciplineTabOptions.map((option, idx) => {
              if (schoolDisciplineTab !== option.value) return null

              return (
                <div
                  key={option.value}
                  className={`tab-content tab-content-${idx + 1}`}
                >
                  <SchoolDisciplineTypeTabContent
                    subDisciplineOptions={getSubdisciplineOptions(
                      disciplines,
                      option.value
                    )}
                    subDisciplinesOffered={subDisciplines}
                  />
                </div>
              )
            })}
          </div>
        </section>
      )}

      {/*Space Type Allocations*/}
      {hasSpaceTypeAllocations && (
        <section name="space-types" className="space-types-wrapper">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <SpaceTypeAllocationsIcon />
              </div>
              <h2>Space Type Allocations</h2>
            </SectionHeader>

            <div>
              <SpaceTypesContent
                spaceTypes={spaceTypeAllocations}
                disciplines={disciplines}
              />
            </div>
          </div>
        </section>
      )}

      {/* Courses */}
      {hasCourses && (
        <section name="courses" className="courses-block">
          <div className="content-block-container">
            <SectionHeader>
              <div className="image-block">
                <SchoolIcon />
                <span>{countCourses(courses)}</span>
              </div>
              <h2>Courses {getSchoolYearDisplay(courseYear(courses))}</h2>
              <div className="text-block">
                {category === Types.COMBO_SCHOOL_TYPE && (
                  <p>
                    Average minutes of weekly instruction calculated for
                    elementary courses only.
                  </p>
                )}
                {courseYear(courses) !== schoolYearId && <DataNote />}
              </div>
            </SectionHeader>
            <CoursesExpandableDetails
              disciplines={disciplines}
              courseInformation={courses}
              schoolCategory={category}
            />
            <p>Student enrollment numbers are rounded up to the nearest ten.</p>
          </div>
        </section>
      )}

      {/* Arts Partners */}
      {hasPartners && (
        <section name="partners">
          <div className="content-block-container partners-block">
            <SectionHeader>
              <div className="image-block">
                <PartnershipsIcon />
                <span>{partnerships.length}</span>
              </div>
              <h2>Arts Partners</h2>
              <SegmentedControl
                options={['List', 'Map']}
                value={mapShown ? 'Map' : 'List'}
                onChange={(val) => setMapShown(val === 'Map')}
              />
            </SectionHeader>
            {mapShown ? (
              <MapDisplay
                locations={[...partnerLocations, schoolLocation]}
                autoZoom
              />
            ) : (
              <MapPartnerTable
                programNames={programNames}
                schoolYearId={selectedSchoolYearId}
                partners={partnerships}
                type={Types.PARTNER_TYPE}
              />
            )}
          </div>
        </section>
      )}
    </LoadingContainer>
  )
}

SchoolContent.propTypes = propTypes
SchoolContent.defaultProps = defaultProps

export default SchoolContent
