import { flatMap, omit, omitBy } from 'lodash'
import { SCHOOL_TYPE } from 'types'
import { isBlank } from 'utils'

function generateMappedFilters({ filters, type, schoolYear }) {
  const mappedFilters =
    type === SCHOOL_TYPE
      ? mapSchoolFilters(filters, { schoolYear })
      : mapOrganizationFilters(filters, { schoolYear })

  return omitBy(mappedFilters, isBlank)
}

// ----- PRIVATE -----

function mapSchoolFilters(filters, { schoolYear } = {}) {
  return {
    schoolYear: schoolYear,
    networkId: filters.network,
    creativeSchoolsCategory: filters.creativeSchoolsCategory,
    instructorsTeaching: filters.instructorsTeaching,
    schoolType: filters.schoolType,
    partnersCount: filters.organizationPartnersCount,
    programsOffered: flatMap(filters.programsOffered),
    disciplineInterests: flatMap(filters.disciplineInterests),
    resourceProgramInterests: flatMap(filters.resourceProgramInterests),
    studentDemographics: flatMap(
      omit(filters.studentDemographics, 'ethnicityType')
    ),
    coursesOffered: filters.coursesOffered,
    districtId: filters.schoolDistrict,
    openToPartnerships: filters.openToPartnerships,
    region: filters.region,
  }
}

function mapOrganizationFilters(filters, { schoolYear } = {}) {
  return {
    schoolYear: schoolYear,
    disciplines: filters.partnerDisciplines,
    partnersCount: filters.schoolPartnersCount,
    programTypes: filters.programTypes,
    programDisciplines: flatMap(filters.programDisciplines),
    gradesServed: filters.gradesServed,
    outcomeTypes: filters.outcomeTypes,
    financialAssistanceTypes: filters.financialAssistanceTypes,
    openToPartnerships: filters.openToPartnerships,
  }
}

export default generateMappedFilters
