import logo from 'images/communities/Michigan/logo.svg'
import footerLogoOne from 'images/communities/Michigan/MAC-Logo-white_NO-tag.svg'
import footerLogoTwo from 'images/communities/Michigan/MAEIA-logo_color.svg'
import footerLogoThree from 'images/communities/Michigan/MACC-Logo-black-and-white.svg'
import footerLogoFour from 'images/communities/Michigan/MI-DOE-Logo.svg'
import dashboardHeaderImage from 'images/communities/Michigan/banner.svg'
import favicon from 'images/communities/Michigan/favicon.png'
import homeLogo from 'images/home/community-logos/michigan.png'
import loginBackgroundImg from 'images/communities/Michigan/login-background-image.svg'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'michigan',
  name: 'Michigan',
  position: 12,
  partnerSupportEmail: 'artlook@MICreativePotential.org',
  schoolSupportEmail: 'artlook@MICreativePotential.org',
  stylesheet: 'michigan.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.micreativepotential.org ',
  // secondaryOrganizationUrl: String, // optional, likely corresponds to the imported secondaryLogo
  // thirdOrganizationUrl: String, // optional, likely corresponds to the imported thirdLogo
  // faqUrl: 'https://www.example.com',
  additionalUrls: [
    {
      src: 'https://maeia-artsednetwork.org/',
      title: 'MAEIA Toolkit',
    },
  ],
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  // faqUrlEducator: String, // only needed if educatorEnabled === true
  // howToVideos: String, // only needed if educatorEnabled === true
  id: 80,
}

// Educator Config
// Only needed if educatorEnabled === true
// config.educator = {
//   communityOfPracticeEnabled: false,
// }

// Search
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  map: {
    center: { lat: 44.314806, lng: -85.602389 },
    radius: 250, // in miles
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'openToPartnerships',
    'region',
  ],
  // directoryFilters: {
  //   blockList: [], // only needed if educatorEnabled === true
  // },
  // forumFilters: {
  //   blockList: [], // only needed if educatorEnabled === true
  // },
}

// Branding
config.defaultBranding = {
  className: 'michigan-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Michigan Creative Potential's hompage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Michigan Creative Potential',
  },
  footerLogos: [
    {
      src: footerLogoOne,
      alt: 'Michigan Assessment Consortium',
    },
    {
      src: footerLogoTwo,
      alt: 'maeia',
    },
    {
      src: footerLogoThree,
      alt: 'Michigan Arts & Culture Council',
    },
    {
      src: footerLogoFour,
      alt: 'Michigan Department of Education',
    },
  ],
  // a secondaryLogo is optional and only 1 of the 2 options can be enabled at once
  // secondaryFooterLogo: {
  //   src: secondaryLogo,
  //   alt: '',
  //   link: config.secondaryOrganizationUrl,
  // },
  // secondarySideBySideLogo: {
  //   src: secondaryLogo,
  //   alt: '',
  //   link: config.secondaryOrganizationUrl,
  // },
  // an additional third side-by-side logo is optional
  // thirdSideBySideLogo: {
  //   src: thirdLogo,
  //   alt: '',
  //   link: config.thirdOrganizationUrl,
  // },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: dashboardHeaderImage,
    alt: '',
  },
  // educatorLogo: {
  //   src: educatorLogo,
  //   alt: '',
  // }, // only needed if educatorEnabled === true
  // educatorSponsorLogo: {
  //   src: educatorSponsorLogo,
  //   atl: '',
  // }, // only needed if educatorEnabled === true
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: advancing arts education in Michigan',
    searchHeader: 'Find Schools & Partners in Michigan',
    welcomeSearchHeader: 'Explore Arts Education in Michigan',
    welcomeHeader: `artlook<sup>®</sup> Michigan`,
    welcomeMessage: `
      <p>
        Become part of MI Creative Potential's collective impact initiative and help create a comprehensive picture of the arts education landscape in our state. We are expanding arts education in our local, regional, and statewide communities. Join artlook<sup>®</sup> Michigan to advance arts education for every child, in every grade, in every school.
      </p>
      <p>We use artlook<sup>®</sup> Michigan to:</p>
      <p>
        <ul class="bulleted-list">
          <li>share data and glean insights</li>
          <li>connect prospective collaborators</li>
          <li>measure growth and support continuous improvement.</li>
        </ul>
      </p>
      <p>
        MI Creative Potential advances equitable access to quality arts education in Michigan. With support from: Michigan Department of Education, Michigan Assessment Consortium, Michigan Arts & Culture Council, and the National Endowment for the Arts.
      </p>
      <p>
        Together we can meet our creative potential.
      </p>
    `,
  },
  tagline: 'Together we can advance arts education in Michigan.',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Michigan</em> is YOUR portal to supplement arts education in K-12 schools.</h3>
        <p>Use <em>artlook<sup>®</sup> Michigan</em> to connect to schools seeking collaboration with arts education partners in dance, media arts, music, theatre, and visual arts.</p>
        <p>
          <strong>To get started, follow the link below to complete a partner profile for your teaching artist or organizational arts education residencies and projects.</strong>
        </p>
        <p> Please reach out with questions: <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.</p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Michigan</em> is YOUR portal to supplement arts education in K-12 schools.</h3>
        <p>
          Use <em>artlook<sup>®</sup> Michigan</em> to connect to teaching artists and community arts education providers available to collaborate in dance, media arts, music, theatre, and visual arts education.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p> Please reach out with questions: <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>

      `,
    },
  },
  educatorPortal: {
    welcomdMessage: ``,
  },
  survey: {
    getStarted: `
      <h2>Welcome to the MI Creative Schools survey!</h2>
      <p>You will add your school's information to share about your arts education instruction, staffing, community partnerships, and resource needs.</p>
      <p>Please save frequently to avoid losing data. You can complete your survey in multiple sessions; saving as you respond to each question will allow you to return to edit your survey before submitting.</p>
      <p>Your data, collected together with schools from across the state, will advance access to quality arts education in Michigan's schools.</p>
    `,
    staffingQuestion: {
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
    scholarshipQuestion: {
      displayOptions: {
        showExample: true,
      },
    },
    staffingModal: ``,
    courses: {
      classMoniker: '',
      coursesNote: ``,
      minutesNotes: ``,
      instructionalDepthNote: ``,
    },
    percentAccess: {
      question: '',
      description: '',
      courseEnrollmentLabel: '',
      error: '',
    },
    obstaclesSelect: {
      question: '',
    },
    terms: ``,
  },
}

// ----- SURVEY QUESTIONS CONFIG -----
// Confirm with the Community that these are the correctly enabled Survey Questions

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
}

export default config
